<template>
  <v-select
    v-model="selectedLanguage"
    :clearable="false"
    :placeholder="$t('form.type-placeholder')"
    :options="locales"
    :reduce="locale=>locale.value"
    label="text"
    class="v-select"
    @input="changeLanguage"
  />
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'LanguageSelector',
  components: {
    vSelect,
  },
  data() {
    return {
      selectedLanguage: this.currentLocale,
    };
  },
  computed: {
    locales() {
      const { locales } = this.$store.getters;
      const communityLanguages = locales.filter((language) => this.availableLanguages.includes(language.value));
      return communityLanguages;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.slug ? this.mainCollective : this.currentCollective;
    },
    availableLanguages() {
      return this.mainSpace?.availableLanguages;
    },
    currentLocale() {
      const { currentLocale } = this.$store.state.locale;
      return this.availableLanguages?.find((item) => item === currentLocale) ? currentLocale : this.mainSpace.languageCode;
    },
  },
  created() {
    this.selectedLanguage = this.currentLocale;
    // console.log('this.mainSpace', this.mainSpace);
    // console.log('this.availableLanguages', this.availableLanguages);
    this.changeLanguage();
  },
  methods: {
    changeLanguage() {
      this.$emit('selectLanguage', this.selectedLanguage);
    },
  },
};
</script>
<style>
.v-select {
  min-width: 160px;
}
.vs__dropdown-toggle {
    padding: 0 2px;
    height: 38px !important;
}

</style>
